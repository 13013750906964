$color-background-dark: #1E2227;
$color-background-darker: #15171A;
$color-foreground: #DAE4ED;
$color-foreground-dark: lighten($color-background-dark, 30%);

$color-background-light: #DAE4ED;
$color-background-lighter: lighten($color-background-light, 7%);
$color-foreground-dark: #1E2227;
$color-foreground-darker: #15171A;

$color-pink: #F24784;
$color-red: #FC4957;
$color-orange: #EA9D53;
$color-yellow: #FCBC28;
$color-bright-yellow: #FFFF00;
$color-teal: #4BC997;
$color-green: #50BD6A;
$color-cyan: #4FC1E4;
$color-blue: #637AFE;
$color-indigo: #6775C4;
$color-purple: #8E59D7;

.color-pink { color: $color-pink !important; }
.color-red { color: $color-red !important; }
.color-orange { color: $color-orange !important; }
.color-yellow { color: $color-yellow !important; }
.color-bright-yellow { color: $color-bright-yellow !important; }
.color-teal { color: $color-teal !important; }
.color-green { color: $color-green !important; }
.color-cyan { color: $color-cyan !important; }
.color-blue { color: $color-blue !important; }
.color-indigo { color: $color-indigo !important; }
.color-purple { color: $color-purple !important; }

.color-pink-hover:hover { color: $color-pink !important; }
.color-red-hover:hover { color: $color-red !important; }
.color-orange-hover:hover { color: $color-orange !important; }
.color-yellow-hover:hover { color: $color-yellow !important; }
.color-teal-hover:hover { color: $color-teal !important; }
.color-green-hover:hover { color: $color-green !important; }
.color-cyan-hover:hover { color: $color-cyan !important; }
.color-blue-hover:hover { color: $color-blue !important; }
.color-indigo-hover:hover { color: $color-indigo !important; }
.color-purple-hover:hover { color: $color-purple !important; }
